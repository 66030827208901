:root {
  --button-color: var(--black);
  --button-bg: transparent;
}

.button,
.btn {
  display: inline-block;
  padding: 0.5em 1em;
  cursor: pointer;

  border-radius: 2em;
  border: 2px solid var(--button-color);
  background: var(--button-bg);

  font-variation-settings: "wght" 600;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  text-decoration: none;
  color: var(--button-color);
}

.button--icon {
  display: inline-flex;
  align-items: center;
  --icon-size: 1.5em;
  --icon-color: var(--button-color);
  .icon {
    transform: translateX(0.25em);
  }
}

.button--clean {
  border: none;
  background: none;
}

.button--filter {
  border-color: var(--accent-color);
  &.active {
    background: var(--accent-color);
    color: var(--button-color-active, --button-color);
  }
}
