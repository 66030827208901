@import "./css/reset.css";
@import "./css/admin.css";
@import "./css/utils.css";
@import "./css/colors.css";
@import "./css/typography.css";
@import "./css/layout.css";
@import "./css/grid.css";
@import "./css/icons.css";
@import "./css/buttons.css";
@import "./css/forms.css";
@import "./css/header.css";
@import "./css/footer.css";
@import "./css/content.css";
@import "./css/articles.css";
@import "./css/comments.css";
@import "./css/masonry.css";
@import "./css/snippets.css";
@import "./css/cookiecontrol.css";

:root {
  --white: #ffffff;
  --black: #000000;
  --sk-black: #171b1d;
  --sk-gray: #383838;
  --sk-blue: #64d1ff;
  --sk-blue-dark: #00b4ff;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
}

.embedded-post__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.embedded-video__container > div {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.6615620214%;
}

.embedded-video__container > div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
