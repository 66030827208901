:root {
  --grid-min: 20rem;
  --grid-gap: 1rem;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--grid-min), 1fr));
  grid-gap: var(--grid-gap);
}
