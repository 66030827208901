:root {
  --nav2-slice-w: 20rem;
  @media (--media-md) {
    --nav2-slice-w: 24rem;
  }
  --nav-slice-w: 12rem;
}

header {
  background: linear-gradient(
    to bottom,
    var(--sk-black),
    var(--sk-black) 5.5rem,
    var(--sk-blue) 5.5rem
  );
  --button-color: var(--sk-blue);
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding-top: 0.8rem;
  padding-bottom: 0.3rem;
}

.logo-box {
  img {
    height: 6.2rem;
    margin-top: 3px;
  }
}

.main-nav {
  position: absolute;

  top: 0;
  right: 0;

  padding-top: 1rem;
  padding-bottom: 1rem;

  height: 100%;
  max-width: 100%;

  background: var(--sk-blue);
  --button-color: var(--black);

  transform: translateX(100%);
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  padding-right: calc(0.5 * (100vw - var(--container-w)) + var(--container-p));

  z-index: 99;

  .nav-toggle {
    display: flex;
    justify-content: right;
  }

  .nav-list,
  .subnav-list {
    margin-top: 2.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .nav-list {
    width: var(--nav-slice-w);
    max-width: 100vw;
  }

  .nav-link {
    font-size: 1.8rem;
    @media (--media-md) {
      font-size: 2.25rem;
    }
  }
}

.nav-link {
  font-variation-settings: "wght" 400;
  transition: font-variation-settings 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover,
  &:focus-visible {
    font-variation-settings: "wght" 900;
  }
}

.button.nav-link {
  padding: 0;
}

.main-subnav {
  position: absolute;
  top: 0;
  left: 0;

  width: 0rem;
  overflow-x: hidden;

  height: 100%;

  padding: 1.5rem 0 1.5rem;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  background: var(--sk-blue-dark);

  .nav-item {
    min-width: calc(var(--nav2-slice-w) - 6rem);
  }
}

body {
  overflow-x: hidden;
}

.menu-expanded,
.submenu-expanded {
  max-height: 100vh;
  overflow: hidden;

  .main-nav {
    transform: translateX(0);
  }
  .nav-toggle.open {
    display: none;
  }
  .nav-toggle.close {
    display: flex;
  }
}

.submenu-expanded {
  .main-nav {
    transform: translateX(calc(var(--nav-slice-w) + 4rem));
    @media (--media-md) {
      transform: translateX(0);
    }
  }

  .main-subnav {
    width: var(--nav2-slice-w);
    transform: translateX(calc(-1 * var(--nav2-slice-w)));
  }
}

.nav-toggle {
  .button--icon {
    --icon-size: 2.5rem;
  }
}

.nav-toggle.open {
  position: relative;
  display: flex;
  margin-bottom: auto;
  margin-top: 0.2rem;

  .runner {
    align-items: center;
    display: flex;
    position: absolute;
    right: -1.75rem;

    --icon-color: var(--sk-gray);
  }

  &:hover,
  &:focus-visible {
    & .runner {
      --icon-color: var(--white);
    }
  }
}
