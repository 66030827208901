.masonry-grid {
  display: grid;
  --columns: 1;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: 1rem;

  @media (--media-md) {
    --columns: 2;
  }
}

.masonry-grid .ce--image {
  display: inline-block;
  margin: 0 0 1rem 0;
  padding: 0;
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover,
    &:focus-visible {
      transform: scale(1.05);
    }
  }
}
