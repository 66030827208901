.f3cc {
  --f3cc-background: #e9e9e9;
  --f3cc-foreground: var(--sk-black);
  --f3cc-button-background: var(--white);
  --f3cc-accept-background: var(--gelb);
  --f3cc-button-foreground: var(--black);
  --f3cc-accept-foreground: var(--black);
  .f3cc-button {
    padding: 0.5em 1em;
    cursor: pointer;
    border-radius: 2em;
    border: 2px solid var(--button-color);
  }
}
