:root {
  --cyan: #64d1ff;
  --blau: #38b4ff;
  --violett: #d48aff;
  --magenta: #ff87b6;
  --rot: #fa8089;
  --orange: #f8a264;
  --gelb: #ffdc52;
  --tuerkis: #00c9cc;
  --gruen: #6ec972;
}

.accent-cyan {
  --accent-color: var(--cyan);
}

.accent-blau {
  --accent-color: var(--blau);
}

.accent-violett {
  --accent-color: var(--violett);
}

.accent-magenta {
  --accent-color: var(--magenta);
}

.accent-rot {
  --accent-color: var(--rot);
}

.accent-orange {
  --accent-color: var(--orange);
}

.accent-gelb {
  --accent-color: var(--gelb);
}

.accent-tuerkis {
  --accent-color: var(--tuerkis);
}

.accent-gruen {
  --accent-color: var(--gruen);
}

.accent-neutral {
  --accent-color: var(--black);
  --button-color-active: var(--white);
}
