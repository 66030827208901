@font-face {
  font-family: Excon;
  font-weight: normal;
  font-style: normal;
  src: url(/fonts/Excon-Variable.woff2) format("woff2");
  font-variation-settings: "wght" 400;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: Excon, Helvetica, Arial, sans-serif;
}

a[class]:not(.button) {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
.lead {
  font-variation-settings: "wght" 700;
}

strong {
  font-variation-settings: "wght" 700;
}

h1,
h2,
h3 {
  margin-bottom: 1em;
  margin-top: 1.75em;
}

.decorated,
.ce h1,
.ce h2,
.ce h3 {
  line-height: 1.6;
  margin-left: 0.3rem;
  margin-right: 0.3rem;

  span {
    box-decoration-break: clone;
    display: inline;
    background: var(--accent-color);
    box-shadow:
      0.3rem 0 0 var(--accent-color),
      -0.3rem 0 0 var(--accent-color);
  }
  /* text-shadow: -2px -2px 0px var(--accent-color),
    2px -2px 0px var(--accent-color); */
}
