:root {
  --icon-size: 2rem;
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);

  fill: var(--icon-color);
}

#icon-hamburger,
#icon-run {
  transform: scale(0.8);
  transform-origin: center;
}

#icon-hamburger {
  stroke: var(--icon-color);
}
