/* top10 */
.ce--top10 {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);

  .graph-container,
  .graph {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--grid-gap);

    &.active {
      display: flex;
    }
  }

  .graph-tabs,
  .graph-toggles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--grid-gap);
  }

  .button--toggle {
    font-size: 0.75rem;
  }

  .button.active:not(.button--clean) {
    background-color: var(--accent-color);
  }

  /* .graphs {
    width: 100%;
  }
  .graph {
    width: 100%;
    height: 20rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  } */
}
