#articles-app {
  scroll-margin: 4rem;
}

.article-list {
  --grid-gap: 2rem;
  min-height: 12rem;
}

.article-list--similar-articles,
.similar-articles-separator {
  margin-top: 4rem;
}

.filter-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 0 0 2rem 0;
}

.filter-buttons__wrapper {
  display: flex;
  flex-direction: column;
}

.filter-buttons.filter-buttons--topic-of-the-year {
  /* margin: 2rem 0 1rem 0; */
}

.article-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  overflow: hidden;

  &:hover,
  &:focus-visible {
    .article-card__image img {
      transform: scale(1.05);
    }
    .more-link {
      font-variation-settings: "wght" 900;
    }
  }

  h2 {
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  p {
    font-variation-settings: "wght" 400;
  }
}

.article-card__image {
  overflow: hidden;
  img {
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%;
  }
}

.article-card__active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.category-tag {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-variation-settings: "wght" 500;
  background: var(--accent-color);
  padding: 0 0.3em;

  &.accent-neutral {
    color: var(--white);
  }
}

.more-link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-variation-settings: "wght" 600;
  transition: font-variation-settings 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.articles h1 {
  margin-top: 1rem;
}

.article-meta {
  display: flex;
  margin: 1rem 0;
}

.article-categories {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__list-item {
    a {
      font-size: 0.875rem;
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      font-variation-settings: "wght" 600;
      letter-spacing: 0.05rem;

      &:hover,
      &:focus-visible {
        font-variation-settings: "wght" 900;
      }
    }
  }
  &__list-item:not(:last-of-type) {
    &::after {
      content: "—";
      margin: 0 1rem;
    }
  }
}

.socialmedia-icons {
  margin-left: auto;
}
