.rule {
  margin: var(--block-margin) 0;
  border-top: 1px solid var(--black);
}

.ce {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ce--download {
  --icon-size: 1.5em;
}

.prose.ce--quote {
  margin: 3rem auto;
  font-variation-settings: "wght" 600;
  p {
    font-size: 1.5rem;
  }
}

.prose {
  p {
    margin-bottom: 1em;
    font-size: 1.25rem;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  .lead {
    font-size: 1.75rem;
    letter-spacing: -0.05rem;
  }
  li {
    font-size: 1.25rem;
  }
}

figcaption,
.caption {
  margin-top: 1em;
  font-size: small;
}

.lead-image {
  margin-top: var(--block-margin);
  img {
    width: 100%;
  }
}

.ce--external,
.ce--image,
.ce--top10 {
  margin-top: var(--block-margin);
  margin-bottom: var(--block-margin);
  --container-w: 49.5rem;
}

a:not(.button, .btn, .f3cc-button) {
  color: var(--accent-color);
  font-variation-settings: "wght" 400;
  transition: font-variation-settings 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover,
  &:focus-visible {
    font-variation-settings: "wght" 1000;
  }
}

/* Set fixed-width anchor to fix animation, see achor-animations.js */
.ce p a,
.footer-nav a,
.article-categories a {
  display: inline-block;
  text-align: center;
  overflow: visible;
}

.ce--linkbox {
  padding: 1rem;
  background: var(--accent-color);

  a {
    color: var(--black);
  }

  p {
    font-size: 1rem;
  }

  ul {
    list-style: none;
    position: relative;
    padding: 0;
  }
  li {
    margin-left: 2rem;
    margin-bottom: 0.3rem;
    &::before {
      position: absolute;
      left: 0;
      top: 0.25rem;
      content: " ";
      width: 1.5rem;
      aspect-ratio: 1;
      background: url(/assets/icon-link.svg);
    }
  }

  h2,
  h3 {
    padding: 0;
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
  }
}
