.comments__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .form-group {
    position: relative;
  }

  label {
    position: absolute;
    font-size: 0.6rem;
    top: -0.9rem;
    left: 0;
    opacity: 1;

    transition: opacity 0.2s ease-in;
  }

  input[type="text"],
  textarea {
    border: 2px solid var(--black);
    padding: 0.3rem 0.8rem;

    width: 100%;
    max-width: var(--container-prose);

    &:placeholder-shown + label {
      opacity: 0;
    }
  }

  .help-text {
    text-align: right;
  }

  .has-danger .form-control {
    border-color: #d9534f;
  }
}
.mt-4 {
  margin-top: 1.5rem !important;
}
