footer {
  background: var(--sk-blue);
  margin-top: 8rem;
}

.footer-container {
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (--media-lg) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  a {
    display: flex;
    align-items: center;
  }
}

.footer-nav {
  .nav-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
}
