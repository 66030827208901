:root {
  --block-margin: 3.75rem;

  --container-w: 40rem;
  --container-p: 1rem;

  @media (--media-md) {
    --container-w: 48rem;
    --container-p: 1.33rem;
  }
  @media (--media-lg) {
    --container-w: 64rem;
    --container-p: 2rem;
  }
  @media (--media-xl) {
    --container-w: 80rem;
  }
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  > *:first-child:not(.hero) {
    margin-top: var(--block-margin);
  }
  > *:last-child,
  .hero {
    margin-bottom: var(--block-margin);
  }
}

.container {
  max-width: var(--container-w);

  padding-left: var(--container-p);
  padding-right: var(--container-p);

  margin-left: auto;
  margin-right: auto;

  width: 100%;
}

.container > .container {
  --container-p: 0;
}

.prose {
  --container-w: 80ch;
  max-width: var(--container-w);
}

.hero {
  position: relative;
  .hero__down {
    position: absolute;
    inset: 0;
    padding-bottom: 3rem;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    animation: jumping 2.5s infinite ease-in-out;
    .icon {
      --icon-size: 4rem;
      --icon-color: var(--white);
      position: sticky;
      top: calc(100vh - 7rem);
    }

    &.fade {
      opacity: 0;
    }
  }
}

#content {
  scroll-margin: 6rem;
}

@keyframes jumping {
  100% {
    transform: translateY(1rem);
  }
  50% {
    transform: translateY(0rem);
  }
  0% {
    transform: translateY(1rem);
  }
}
